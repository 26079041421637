import { VehicleSectionProps } from "@/app/interface/components"
import React from "react"
import CustomLink from "@/app/components/UI/CustomLink"
import styles from '@/styles/mobile/Home/VehicleSection.module.scss'
import ImageComponent from "@/app/components/UI/ImageComponent"
import { useMediaQuery } from "react-responsive"

const VehicleSection: React.FC<VehicleSectionProps> = ({ justRequestBtn=false, lancamento=false, newModel=false, title, content, car }) => {
    const isTablet = useMediaQuery({ minWidth: 540 })
    const src = isTablet ? "tablet" : "mobile"
    
    return (
        <section className={styles.main}>
            <div className={styles.content}>
                <div className={styles.contentHeader}>
                    {newModel && (
                        <span className="newModel">
                            Novo
                        </span>
                    )}
                    
                    {lancamento && (
                        <span className="newModel">
                            Lançamento
                        </span>
                    )}

                    <div className={styles.title}>
                        <h2 className="h2-large">{title}</h2>
                        <p>{content}</p>
                    </div>
                    
                </div>
                <div className={styles.links}>
                    {!justRequestBtn && (
                        <>
                            <CustomLink className="btn-tertiary" href={'/' + car}>Saiba mais</CustomLink>
                            <CustomLink className={"btn-tertiary " + "btn-test-drive-" + car} href={'/test-drive/' + car}>Test drive</CustomLink>
                        </>
                    )}
                    <CustomLink className={"btn-tertiary " + "btn-solicitar-proposta-" + car} href={'/solicitar-proposta/' + car}>Solicitar proposta</CustomLink>
                </div>
            </div>
            <ImageComponent
                src={process.env.NEXT_PUBLIC_URL_IMG + '/byd/' + src + '/home/' + car + '.webp'}
                alt=""
                width={1024}
                height={1764}
                loading={newModel ? 'eager' : 'lazy'}
            />
        </section>
    )
}

export default React.memo(VehicleSection)
